.contact--girlfriends{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 6vh;
}

.contact--txt1{
    font-size: 1.1rem;
    color: #F29A21;
}

.contact--txt2{
    font-size: 2rem;
    font-weight: bold;
}

.contact--txt3{
    font-size: 1.2rem;
    font-weight: bold;
}

.contact--malithi{
    display: flex;
    flex-direction: column;
    row-gap: 5vh;
}

.contact--tashi{
    display: flex;
    flex-direction: column;
    padding-left: 6vw;
    row-gap: 3vh;
}

.contact--sayuu{
    width: 100%;
    background-color: #7E5920;
    padding-top: 3vh;
    padding-bottom: 5vh;
}

.contact--sadi{
    width: 90%;
    height: 50vh;
    padding: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact--Hashi{
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 540px) and (max-width: 950px) {

    .contact--malithi{
        row-gap: 6vh;
    }
  
    .contact--txt1{
        font-size: 1.3rem;
    }
    
    .contact--txt2{
        font-size: 2.4rem;
    }

    .contact--txt3{
        font-size: 1.7rem;
    }

    .contact--txt4{
        font-size: 1.3rem;
    }

}

@media screen and (min-width: 950px) {

    .contact--girlfriends{
        padding-bottom: 8vh;
    }

    .contact--malithi{
        width: 96%;
        padding-left: 2%;
        padding-right: 2%;
        display: flex;
        flex-direction: row;
        column-gap: 1vh;
        justify-content: center;
        align-items: center;
    }

    .contact--tashi{
        width: 50%;
        row-gap: 5vh;
    }
    
    .contact--sayuu{
        width: 30%;
        border-radius: 0.8rem;
        box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, alpha);
        padding-bottom: 4vh;
    }

    .contact--txt1{
        font-size: 1.2rem;
        font-weight: bold;
    }
    
    .contact--txt2{
        font-size: 2.3rem;
    }

    .contact--txt3{
        font-size: 1.3rem;
    }

    .contact--txt4{
        font-size: 1.1rem;
    }

    .contact--sadi{
        width: 90%;
    }

}