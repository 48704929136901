body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}
  
.footer--sec {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1D1B20;
}

.footer--sec--div{
    width: 96%;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
}

.footer--malithi{
    font-weight: bold;
}

.footer--logo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 2.7rem;
    margin-top: 6vh;
    color: white;
}

.footer--contactdtail{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    padding-left: 2vw;
    /* align-items: center; */
    /* text-align: center; */
    color: white;
    row-gap: 1vh;
    font-size: 1rem;
}

.footer--header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 3vw;
    /* align-items: center; */
    margin-top: 3vh;
    row-gap: 1vh;
}

.footer--nav {
    font-size: 2.5vh;
    color: white;
    font-family: RobotoBold;
    font-weight: bold;
    text-decoration: none;
}

.icon--div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 1vh;
    margin-top: 4vh;
}

.footer--sec2{
    width: 80%;
    border: 1px solid rgba(255, 255, 255,0.85);
    margin-top: 5%;
}
 
.footer--line{
    width: 90%;
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer--copyright {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    justify-self: flex-end;
    align-items: center;
    font-family: RR;
    font-weight: bold;
    color: white;
    font-size: 2vh;
    padding-top: 1.5vh;
    padding-bottom: 1vh;
}

.footer--txt {
    color: white;
    text-align: center;
    font-size: 4vh;
    font-family: RR;
    font-weight: bold;
    margin-top: 10%;
}

.footer--sec--div3{
    margin-bottom: 4%;
}

.footer--outer{
    display: flex;
    flex-direction: row;
    column-gap: 5vh;
    margin-top: 2vh;
    margin-bottom: 2.5vh;
}

.footer--outTag{
    color: #666666;
    font-size: 0.8rem;
}

@media screen and (min-width: 540px) and (max-width: 950px) {

.footer--txt{
    font-size: 3vh;
}

.footer--contactdtail{
    font-size: 1.5rem;
}

.footer--malithi{
    font-weight: bold;
}

.footer--nav{
    font-size: 2.4vh;
}

.icon--div{
    margin-top: 2vh;
}

.footer--outTag{
    font-size: 1.3rem;
}

}

@media screen and (min-width: 950px) {

.footer--sec{
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer--sec2{
    width: 90%;
    display: flex;
    flex-direction: row;
}

.footer--sec--div{
    width: 30%;
}

.footer--sec--div2{
    width: 30%;
}

.footer--sec--div3{
    width: 30%;
}

.footer--line{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vh;
}

.footer--logo{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    margin-top: 1vh;
}

.footer--contactdtail{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    padding-bottom: 5vh;
}

.footer--header{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    margin-top: 6vh;
}

.footer--nav{
    font-size: 1.25rem;
}

.footer--txt{
    text-align: left;
    font-size: 1.4rem;
    margin-top: 8%;
}

.icon--div{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    margin-top: 2vh;
}

.footer--copyright{
    width: 70%;
    padding-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    font-weight: normal;
}

.footer--outer{
    width: 30%;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: flex-end;
}

.footer--outTag{
    font-size: 0.7rem;
}


}
