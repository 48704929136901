@font-face {
  font-family: "RobotoLight";
  src: url("../../_resources/fonts/robotoLight.ttf") format("truetype");
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 80%;
  /* aspect-ratio: 0.55; */
  height: 58vh;
  background-color: #fff;
  margin: auto;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.card:hover{
  transform: scale(1.05);
}

.card-image {
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  align-items: center;
  margin: auto;
}

.card-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.card-title {
  font-size: 1.5em;
  height: 9vh;
  margin: 0;
  font-family: "Roboto";
  color: var(--color5);
}

.card-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  font-family: "RobotoLight";
  font-size: 0.9rem;
  color: var(--color5);
  font-size: 0.9rem;
}

.card-price {
  font-size: 1em;
  color: #000000;
  font-family: "Roboto";
}

@media screen and (min-width: 415px) {
  .card{
    height: 65vh;
  }
}

@media screen and (min-width: 500px) {
  .card{
    height: 70vh;
  }
}

@media screen and (min-width: 540px) {
  .card{
    height: 50vh;
  }
}

@media screen and (min-width: 600px) {
  .card{
    height: 55vh;
  }
}

@media screen and (min-width: 730px) {
  .card{
    height: 65vh;
  }
}

@media screen and (min-width: 950px) {
  .card{
    height: 70vh;
  }
}