@keyframes fadeUpIn {
  from{
    opacity: 0;
    transform: translateY(-30%);
  }
  to{
    transform: translateY(0);
    opacity: 1;
    z-index: 0;
  }
}

@font-face {
  font-family: "Roboto";
  src: url("../../_resources/fonts/roboto.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoLight";
  src: url("../../_resources/fonts/robotoLight.ttf") format("truetype");
}

.hero {
  width: 85vw;
  height: 95vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5vh;
  padding-left: 15%;
  font-family: "Roboto";
}

.hero--row1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  padding-left: 0%;
  padding-right: 2%;
  animation: fadeUpIn 1s forwards;
}

.hero--heading {
  font-size: 2rem;
  margin: 0px;
  width: 90%;
  color: var(--color2);
  animation: fadeUpIn;
}

.hero--headingPt2{
  color: var(--color2);
  margin: 0px;
  font-size: 2.5rem;
  width: 50%;
}

.hero--row2 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  padding-left: 1%;
  padding-right: 2%;
  animation: fadeUpIn 1s forwards;
}

.hero--row3{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 85%;
  padding-left: 1%;
  padding-right: 2%;
  animation: fadeUpIn 1s forwards;
  margin-top: 2%;
}

.hero--btn{
  transition: all 0.3s;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 4%;
  padding-bottom: 4%;
  background-color: var(--color4);
  color: var(--color2);
  border: none;
  border-radius: 0.3rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.hero--btn:hover{
  cursor: pointer;
  transform: scale(1.05);
}

.hero--subHeading {
  font-family: "robotoLight";
  font-size: 0.9rem;
  margin: 0;
  margin-top: 1vh;
  font-weight: normal;
  width: 85%;
  color: var(--color2);
}

@media screen and (min-width: 540px) and (max-width: 950px) {
  .hero--heading {
    font-size: 3rem;
    width: 75%;
  }

  .hero--row3{
    width: 100%;
    justify-content: flex-start;
  }

  .hero--btn{
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .hero--subHeading {
    font-size: 1rem;
  }
}

@media screen and (min-width: 950px) {
  .hero--heading {
    font-size: 2.2rem;
    width: 50%;
  }

  .hero--headingPt2{
    font-size: 3rem;
    width: 50%;
  }

  .hero--row3{
    width: 100%;
    justify-content: flex-start;
  }

  .hero--btn{
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 0.8rem;
  }

  .hero--subHeading {
    font-size: 1.2rem;
  }
}
