:root {
  margin: 0px;
  padding: 0px;
  font-family: "Inter";
  --color1: #393e46;
  --color2: #f7f7f7;
  --color3: #6d9886;
  --color4: #F29A21;
  --color5: #7E5920;
  --color6: #816C61;
  --color7: #D9D9D9;
  --color1rgb: 57, 62, 70; /* #393e46 */
  --color2rgb: 247, 247, 247; /* #f7f7f7 */
  --color3rgb: 109, 152, 134; /* #6d9886 */
  --color4rgb: 255, 232, 20; /* #ffe814 */
}

@font-face {
  font-family: "Roboto";
  src: url("_resources/fonts/roboto.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoLight";
  src: url("_resources/fonts/robotoLight.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  src: url("_resources/fonts/Roboto-Bold.ttf") format("truetype");
}
