.blog-card-container{
    display:flex;
    background-color: var(--color7);
    border-radius: 0.7rem;
    box-shadow: 0 2px 4px rgba(0,0,0,1);
    overflow: hidden;
    border: none;
    margin-bottom: 3vh;
    width: 90%;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.blog-card-container:hover {
    transform: scale(1.03);
}   

.blog-card-container.row{
    flex-direction: row;
}

.blog-card-container.column{
    flex-direction: column;
}
.blog-image-container{
    overflow: hidden;
    flex-wrap: wrap;
    display: flex;
}
.blog-card-image{
    width: 100%;
    height: 100%;
    object-fit:cover
}
.blog-card-content{
    padding-left: 2vh;
    padding-right: 2vh;
}
.blog-number{
    font-family: RobotoBold;
    color: var(--color4);
}
.blog-title{
    font-family: RobotoBold;
    color: var(--color5);
    
}
.blog-description{
    font-family: Roboto;
    font-size: 0.9rem;
    
}
.blog-button{
    background-color: var(--color4);
    border: none;
    color: var(--color2);
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 2.5vh;
    padding-right: 2.5vh;
    border-radius: 0.5rem;
    font-family: RobotoBold;
    font-size: 1rem;
}
.blog-date{
    font-family: RobotoBold;
}


@media screen and (min-width: 540px){
   
    .blog-card-container{
        width: 70%;
    }

}
@media screen and (min-width: 950px){
    .blog-card-container{
        width: 25%;
    }
    .blog-card-content{
        width: 200%;
    }
}