.product-card-set {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 2%;
    align-items: center;
    /* padding: 16px; */
}

.product-sec--title {
    margin: 5vh;
    color: var(--color5);
    font-family: RobotoBold;
}
/* 
.product-set {
    display: flex;
    flex-direction: column;
} */

@media screen and (min-width: 540px) {
    .product-card-set {
        flex-direction: row;
        padding-left: 5vw;
        /* justify-content: center; */
    }
}

@media screen and (min-width: 950px) {
    .product-card-set {
        flex-direction: row;
        padding-left: 4%;
    }
}
