.product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    width: 75%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    overflow: hidden;
    background-color: #fff;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.product-card:hover {
    transform: scale(1.05);
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.product-title {
    color: var(--color5);
    font-family: RobotoBold;
    font-size: 1.2em;
    margin: 8px 0;
    height: 6vh;
}

.product-description {
    color: var(--color1);
    height: auto;
    font-family: RobotoLight;
    font-size: 0.9em;
    margin: 8px 0;
}

.product-price {
    color: var(--color1);
    font-family: Roboto;
    font-size: 1.1em;
    font-weight: bold;
}

@media screen and (min-width: 540px) {
    .product-card {
        width: 26%;
    }

    .product-description{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .product-card:hover .product-description{
        overflow: visible;
        display: flex;
        -webkit-line-clamp: 10;
        line-clamp: 10;
        -webkit-box-orient: vertical;
    }
}

@media screen and (min-width: 950px) {
    .product-card {
        width: 20%;
    }
    .product-description{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}