.home--sec1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home--sec2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 5vh;
}

.home--sec3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color5);
  padding-bottom: 5vh;
}

.home--sec4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 5vh;
}

.home--sec5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color6);
  padding-bottom: 5vh;
}

.home--sec6 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 5vh;
}

.title {
  font-family: RobotoBold;
  font-size: 1.3rem;
  color: var(--color4);
  line-height: 1;
  margin-top: 8.5vh;
  margin-bottom: 1vh;
}
.subTitle {
  font-family: RobotoBold;
  font-size: 1.7rem;
  font-weight: 900;
  margin: 0;
  margin-bottom: 5vh;
}
.shopAllButton {
  border-radius: 2rem;
  width: 50%;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  font-family: RobotoBold;
  margin-bottom: 5vh;
  margin-top: 8vh;
  border: 4px solid var(--color4);
  color: var(--color4);
  font-size: 1.2rem;
  text-align: center;
  text-decoration: none;
}

.shopAllButton:hover{
  transform: scale(1.03);
  background-color: var(--color4);
  color: var(--color2);
}

.homeSec--paginationBtn {
  font-size: 1.5rem;
  border: none;
  background-color: transparent;
}

.swiper-button-prev {
  left: -10%; /* Position outside the left of the slides */
  z-index: 100000;
}

.sec5--read--button {
  border-radius: 0.5rem;
  width: 50%;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-family: RobotoBold;
  border: 3px solid var(--color4);
  color: var(--color4);
  font-size: 1rem;
  background-color: transparent;
}

.swiper-button-next {
  right: -10%; /* Position outside the right of the slides */
  z-index: 1000;
}
.home-swiper-section {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.home--slider {
  width: 80%;
  margin: 0;
}

.home--sec2--img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.sec2--img--container {
  width: 80%;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  font-family: RobotoBold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.sec2--img--container:hover{
    transform: scale(1.1);
}

.sec3--img--container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.sec3--text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.sec3--paragraph {
  color: var(--color2);
  font-family: RobotoBold;
  margin-top: 0;
}
.sec2--img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  align-items: center;
  margin: auto;
}

.sec3--img {
  width: 90%;
  margin-top: 2vh;
  margin-bottom: 5vh;
  border-radius: 0.5rem;
  object-fit: cotain;
  object-position: center;
}

.learnMoreButton {
  width: 50%;
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-family: RobotoBold;
  margin-bottom: 5vh;
  margin-top: 8vh;
  background-color: var(--color4);
  color: var(--color2);
  font-size: 1.2rem;
  border-radius: 0.3rem;
  border: none;
}

.sec4--cards--container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: center;
  align-items: center;
}

.sec4--cards {
  display: flex;
  flex-direction: column;
  border: 3px solid var(--color4);
  border-radius: 0.7rem;
  color: var(--color5);
  font-family: RobotoBold;
  font-size: 0.9rem;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 90%;
  aspect-ratio: 1;
  padding-top: 2vh;
  margin: 0.6vh;
  align-items: center;
  justify-content: space-evenly;
}

.sec4--imgs {
  width: 70%;
  object-fit: cover;
  border-radius: 0.4rem;
}

.sec5--items {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.sec5--items--divide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home--sec6--img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .sec6--img--container {
    width: 80%;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    font-family: RobotoBold;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .sec6--img--container:hover{
      transform: scale(1.1);
  }

  .sec6--img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    align-items: center;
    margin: auto;
  }

@media screen and (min-width: 540px) {
  .sec4--cards {
    width: 20%;
  }
  .sec3--img {
    width: 45%;
  }
  .sec3--img--container {
    flex-direction: row;
  }
  .sec3--text {
    font-size: 1.4rem;
  }
 
  
}

@media screen and (min-width: 950px) {
  .title {
    font-size: 1rem;
    margin-top: 5vh;
  }
  .subTitle {
    font-family: RobotoBold;
    font-size: 2.2rem;
    font-weight: 900;
    margin: 0;
    margin-bottom: 5vh;
  }

  .home--sec2--img {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .sec2--img--container {
    width: 25%;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    font-size: 1.3rem;
  }
  .shopAllButton {
    width: 13%;
    font-size: 1rem;
    margin-top: 5vh;
  }

  .home--sec3 {
    flex-direction: row;
  }
  .sec3--img--container {
    flex-direction: row;
    margin-top: 5vh;
    margin-right: 5vh;
  }
  .sec3--img {
    width: 45%;
  }
  .sec3--text {
    width: 60%;
    margin-left: 5vh;
    margin-right: 5vh;
    margin-top: 5vh;
    font-size: 1.2rem;
  }
  .learnMoreButton {
    width: 40%;
    font-size: 1.1rem;
  }

  .sec4--cards--container {
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
  }

  .sec4--cards {
    width: 20%;
    font-size: 1.2rem;
  }
  .sec4--imgs {
    width: 50%;
  }
  .sec5--items {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;
    padding-left: 20vh;
    padding-right: 20vh;
  }
  .sec5--items--divide {
    display: flex;
    width: 50%;
    justify-content: flex-start;
    padding: 0vh;
    align-items: flex-start;

  }
  .sec5--read--button{
    width: 30%;
  }
  .home--sec6--img{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .sec6--img--container{
    width: 22%;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    font-size: 1.3rem;
  }
}
