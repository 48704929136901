.title {
  font-family: RobotoBold;
  font-size: 1.3rem;
  color: var(--color4);
  line-height: 1;
  margin-top: 8.5vh;
  margin-bottom: 1vh;
}
.subTitle {
  font-family: RobotoBold;
  font-size: 1.7rem;
  font-weight: 900;
  margin: 0;
  margin-bottom: 5vh;
}

.gallery--titleRow{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}