* {
  font-family: "Roboto";
}

.about--section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about--title {
  font-family: RobotoBold;
  font-size: 1.3rem;
  color: var(--color4);
  line-height: 1;
  margin-top: 8.5vh;
  margin-bottom: 1vh;
}

.about--subTitle {
  font-family: RobotoBold;
  font-size: 1.7rem;
  font-weight: 900;
  margin: 0;
  margin-bottom: 1vh;
}

.about--sec1Row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 2%;
  padding-bottom: 2vh;
}

.about--sec1RowContent {
  padding-left: 4%;
  padding-right: 4%;
}

.about--groupImg {
  width: 100%;
}

.about--p1 {
  line-height: 1;
  color: rgba(var(--color1rgb), 0.9);
}

.about--sec1Details {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: Bold;
  font-family: "RobotoBold";
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}

.about--sec1DetailPt1 {
  color: var(--color5);
  width: 95%;
}

.about--sec1DetailPt2 {
  width: 80%;
  margin-top: 0.2vh;
}

.about--sec1DetailDash {
  display: none;
}

.about--certificates{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about--desc{
    width: 90%;
    text-align: justify;
}

.about--certificate{
    width: 80%;
    margin-top: 0.2vh;
    margin-bottom: 0.2vh;
}

.about--banner{
    width: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color5);
}

.about--bannerTxt{
    color: var(--color2);
    font-weight: bold;
    width: 90%;
    text-align: center;
}

.about--sec2Row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 2%;
  padding-bottom: 2vh;
}

.about--gehan{
  width: 80%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 0.4rem;
}

.about--sec2{
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about--sec2Title{
  color: var(--color5);
  margin-bottom: 0;
}

.about--p2{
  margin: 0;
  text-align: justify;
}

@media screen and (min-width: 540px) {

    .about--certificates{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .about--certificate{
        width: 45%;
    }

    .about--sec1Row{
        width: 90%;
        flex-direction: row-reverse;
    }

    .about--sec2Row{
      width: 90%;
      flex-direction: row;
  }

    .about--groupImg{
        width: 40%;
    }

    .about--desc{
        text-align: center;
    }

    .about--bannerTxt{
        width: 70%;
    }

    .about--gehan{
      width: 30%;
    }
}

@media screen and (min-width: 950px) {

    .about--certificate{
        width: 21%;
    }

    .about--sec1DetailPt2{
        width: 87%;
    }

    .about--sec1Details{
        margin-top: 2vh;
    }
}
