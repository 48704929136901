*{
  transition: none;
}

.gallerySec {
  --swiper-navigation-color: var(--color4);
  --swiper-navigation-size: 5vh;
  --swiper-navigation-sides-offset: 1vw;
  padding-bottom: 3vh;
  width: 100%;
}

.gallerySec--banner {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 2vh;
  margin-top: 2vh;
}

.gallerySec--overlay {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  backdrop-filter: blur(5px);
}

.gallerySec--sliderRow{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gallerySec--paginationBtn{
  font-size: 1.5rem;
  border: none;
}

.gallerySec--slider {
  width: 100%;
}

.gallerySec--slide {
  width: 100%;
  margin: auto;
}

.gallerySec--slideImg {
  width: 100%;
  aspect-ratio: 1.3;
  object-fit: cover;
  border-radius: 0.1rem;
}

.gallerySec--title {
  margin-top: 5vh;
  margin-bottom: 5vh;
  font-weight: normal;
  color: var(--color2);
  max-width: 90%;
}

.swiper-button-prev {
  left: -10%; /* Position outside the left of the slides */
  z-index: 100000;
}

.swiper-button-next {
  right: -10%; /* Position outside the right of the slides */
  z-index: 1000;
}

@media screen and (min-width: 540px) {
  .gallerySec--slider {
    width: 85%;
  }

  .gallerySec--title {
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
}

@media screen and (min-width: 950px) {

}
