*{
  transition: all 2s;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 2%;
}

.form--col {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 0.8vh;
  margin-bottom: 0.8vh;
  row-gap: 1vh;
}

.form--label {
  color: var(--color4);
  font-weight: bold;
}

.form--input {
  width: 98%;
  height: 5vh;
  border: 0.15rem solid rgba(var(--color1rgb), 0.6);
  border-radius: 0.2rem;
  padding-left: 2%;
  border-radius: 0.5rem;
}

.form--textarea {
  height: 15vh;
  border: 0.15rem solid rgba(var(--color1rgb), 0.6);
  width: 96%;
  padding-left: 2%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-right: 2%;
  border-radius: 0.5rem;
}

.form--btn {
  width: 70%;
  height: 6vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border: none;
  background-color: var(--color4);
  font-weight: 600;
  color: var(--color2);
  border-radius: 0.3rem;
  margin-top: 2%;
  font-size: 1.2rem;
}

.form--btn:hover {
  cursor: pointer;
  scale: 1.1;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, alpha);
  color: var(--color1);

}

.form--message{
  width: 90%;
  margin-top: 4%;
  margin-bottom: 1.8%;
  color: rgb(197, 51, 51);
  display: none;
  opacity: 0;
}

.form--message.show{
  display: block;
  opacity: 1;
}

.form--btnRow{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 2%;

}

@media screen and (min-width: 540px) {
  .form--col {
    width: 45%;
  }

  #col5 {
    width: 92%;
    column-gap: 0;
  }

  .form{
    display: flex;
    flex-direction: row;
  }

  .form--btn {
    border-radius: 0.5rem;
    font-size: 1.7rem;
  }

  .form--label{
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 950px) {

  .form--btn {
    font-size: 1.2rem;
    width: 30%;
  }

  .form--label{
    font-size: 1.1rem;
  }

  .form--btnRow{
    display: flex;
    justify-content: left;
  }

  .form{
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-left: 2vw;
  }

  .form--col {
    width: 85%;
  }

  .form--textarea{
    width: 89%;
  }

}
